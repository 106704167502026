<template>
  <!-- container -->
  <div id="home">
    <div class="home-container">
      <div class="slides fade">
        <div class="home-head">
          <img src="../../assets/bchome.png" alt="" style="height: 100vh" />
          <div class="home-head-desc">
            <div
              class="home-desc-left"
              data-aos="fade"
              data-aos-delay="500"
              data-aos-duration="500"
            >
              <h3>Crop Hero</h3>
              <h3>Monitoring</h3>
              <p>
                Mampu mencatat 6 parameter data lingkungan dalam 1 perangkat,
                yaitu suhu udara, suhu air, kelembaban udara, intensitas cahaya,
                pH (keasaman larutan) dan kepekatan nutrisi (electrical
                conductivity). Setiap parameter memiliki threshold minimum dan
                maksimum sesuai data tanaman yang di input user dan kemudian
                akan mengirim sinyal ke unit kontrol apabila kondisi lingkungan
                tidak berada dalam range kondisi optimum tumbuh kembang tanaman
              </p>
            </div>
            <div
              class="home-desc-right"
              data-aos="fade-left"
              data-aos-delay="700"
              data-aos-duration="500"
            >
              <img src="../../assets/hero.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="slides fade">
        <div class="home-service">
          <div class="home-service-inner">
            <div class="home-service-left" data-aos="fade-right">
              <h2>SERVIS<br />KAMI</h2>
            </div>
            <div class="home-service-right" data-aos="fade">
              <img
                src="../../assets/bchome.png"
                alt=""
                class="s1"
                data-aos="fade"
                data-aos-delay="300"
              />
              <img
                src="../../assets/service2.png"
                alt=""
                class="s2"
                data-aos="fade"
                data-aos-delay="300"
              />
              <div class="s1a" data-aos="fade" data-aos-delay="700">
                <h3>Irigasi Lapangan Terbuka</h3>
                <ol>
                  <li>Irigasi tetes atau sprinkler</li>
                  <li>Sensor outdoor</li>
                  <li>
                    Kontrol dan monitor menggunakan aplikasi (CH-O dan CH-C)
                  </li>
                </ol>
              </div>
              <div class="s2a" data-aos="fade" data-aos-delay="700">
                <h3>Smart Greenhouse</h3>
                <ol>
                  <li>Sensor indoor</li>
                  <li>
                    Kontrol dan monitor menggunakan aplikasi (CH-I dan CH-C)
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        data-aos="fade"
        data-aos-delay="900"
        class="prev"
        v-on:click="plusSlides(-1)"
        >&#10094;</a
      >
      <a
        data-aos="fade"
        data-aos-delay="900"
        class="next"
        v-on:click="plusSlides(1)"
        >&#10095;</a
      >
    </div>

    <div style="text-align: center" class="dot-container">
      <span class="dot" v-on:click="currentSlide(1)"></span>
      <span class="dot" v-on:click="currentSlide(2)"></span>
    </div>

    <div class="home-testimoni">
      <div class="home-testimoni-vid">
        <div data-aos="fade-right" data-aos-duration="700">
          <h3>Video</h3>
          <iframe
            src="https://www.youtube.com/embed/Fq0qJH3bhk0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="home-testimoni-txt">
        <h3 data-aos="fade" data-aos-delay="500">Testimoni</h3>

        <carousel
          loop="true"
          :navigationEnabled="true"
          navigationClickTargetSize="10"
          :navigationNextLabel='`<i class="arrow right"></i>`'
          :navigationPrevLabel='`<i class="arrow left"></i>`'
          :paginationEnabled="false"
          easing="ease"
          perPage="1"
          mouseDrag="true"
          
        >
          <slide v-for="(item, index) in data" :key="index">
            <div class="testimoni-txt-inner">
              <!-- <button data-aos="fade" data-aos-delay="600">
                <img src="../../assets/prev.png" alt="" />
              </button> -->
              <p data-aos="fade" data-aos-delay="700">
                {{ item.testimoni }}
              </p>
              <!-- <button data-aos="fade" data-aos-delay="800">
                <img src="../../assets/next.png" alt="" />
              </button> -->
            </div>
            <p class="testi-txt-name" data-aos="fade" data-aos-delay="700">
              <b>{{ item.nama }}</b> <br> - <i>{{ item.jabatan }}</i>
            </p>
          </slide>
        </carousel>

      </div>
    </div>

    <div class="home-footer">
      <div class="home-footer-inner">
        <div class="home-footer-left">
          <img src="../../assets/logobw.png" alt="" />
        </div>
        <div class="home-footer-right">
          <div class="footer-up">
            <p>Pabuaran Sawah RT.003/004,</p>
            <p>Cibanteng, Ciampea, Bogor, Jawa Barat, 16680</p>
          </div>
          <div class="footer-down">
            <div class="ig">
              <img src="../../assets/ig.png" alt="" />
              <p>crophero</p>
            </div>
            <div class="em">
              <img src="../../assets/emai.png" alt="" />
              <p>admin@kharismacrop.com</p>
            </div>
            <div class="br">
              <img src="../../assets/brow.png" alt="" />
              <p>kharismacrop.com</p>
            </div>
            <div class="tp">
              <img src="../../assets/telp.png" alt="" />
              <p>(+62) 812 9770 6625</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import axios from "axios";
import next from "../../assets/next.png";
import prev from "../../assets/prev.png";

export default {
  name: "Home",
  data() {
    return {
      slideIndex: 1,
      data: [],
      nextLabel: next,
      prevLabel: prev,
    };
  },
  methods: {
    getTestimoni() {
      axios.get("https://ydtmch9j99.execute-api.us-east-1.amazonaws.com/dev/api/testimoni")
        .then((res) => {
          this.data = res.data.data;
          console.log(this.data);
        })
    },
    showSlides(n) {
      AOS.refresh();
      var i;
      var slides = document.getElementsByClassName("slides");
      var dots = document.getElementsByClassName("dot");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    },
    plusSlides(n) {
      this.showSlides((this.slideIndex += n));
    },
    currentSlide(n) {
      this.showSlides((this.slideIndex = n));
    },
  },
  mounted() {
    this.showSlides(this.slideIndex);
    this.getTestimoni();
  },
};
</script>

<style>
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
#home {
  width: 100%;
  padding-top: 50px;
  height: 100%;
}

.home-container {
  max-width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
}

.slides {
  display: none;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  color: gray;
  font-weight: bold;
  font-size: 50px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  color: white;
  background-color: rgba(58, 58, 58, 0.6);
}

.dot-container {
  /* border: 2px solid black; */
  position: absolute;
  bottom: 5%;
  left: 5%;
}

.dot {
  /* position: absolute; */
  cursor: pointer;
  height: 15px;
  width: 15px;
  bottom: 15px;
  margin: 0 5px;
  background-color: rgb(173, 173, 173);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #006837;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1s;
  animation-name: fade;
  animation-duration: 1s;
}

@media only screen and (max-width: 1000px) {
  .dot-container {
    width: 90%;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.home-head {
  width: 100%;
  height: 100%;
  /* max-height: 700px; */
  position: relative;
}

.home-head img {
  width: 100%;
  /* max-height: 700px; */
  object-fit: cover;
}

.home-head-desc {
  width: 100%;
  height: 100%;
  /* max-height: 700px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  position: absolute;
  top: 0;
  padding: 0 6em;
}

.home-desc-left {
  width: 60%;
}

.home-desc-left h3 {
  font-size: 3rem;
  color: #006837;
}

.home-desc-left p {
  margin-top: 1.5em;
  font-size: 1.25rem;
}

.home-desc-right {
  width: 35%;
}

.home-desc-right img {
  width: 100%;
}

/* head */
@media only screen and (max-width: 1000px) {
  .home-head img {
    width: 100%;
    height: 100%;
    /* max-height: 700px; */
    object-fit: cover;
  }

  .home-head-desc {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    text-align: center;
    justify-content: start;
    padding: 0;
  }
  .home-desc-right img{
    height: 100%;
    max-height: 350px;
    object-fit: contain;
  }

  .home-desc-right {
    width: 70%;
    margin-top: 4rem;
  }

  .home-desc-left h3 {
    font-size: 2rem;
  }

  .home-desc-left p {
    margin-top: 1.5em;
    font-size: 0.85rem;
  }
}

.home-service {
  width: 100%;
  height: 100vh;
  /* padding: 12.5vh 0; */
  background: linear-gradient(
    180deg,
    rgba(244, 245, 246, 1) 0%,
    rgba(238, 239, 240, 1) 29%,
    rgba(221, 222, 223, 1) 72%,
    rgba(208, 208, 209, 1) 100%
  );
  display: flex;
  align-items: center;
}

.home-service-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  transform: scale(0.9);
  /* padding: 3em 0; */
  width: 95%;
}

.home-service-left {
  width: 30%;
  /* height: 100%; */
}

.home-service-left h2 {
  color: #006837;
  font-size: 4rem;
  font-weight: 400;
}

.home-service-right {
  width: 70%;
  /* height: 75vh; */
  border-left: 4.5px solid #006837;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 2em 1em;
  padding: 2em 4em;
}

.home-service-right img {
  /* height: 30vh;  */
  width: 24vw;
  border: 1px solid #006837;
  object-fit: cover;
}

.home-service-right .s1 {
  /* border: 1px solid black; */
  grid-column: 1/2;
  grid-row: 1/2;
}

.home-service-right .s2 {
  /* border: 1px solid black; */
  grid-column: 1/2;
  grid-row: 2/2;
}

.home-service-right .s1a {
  /* border: 1px solid black; */
  grid-column: 2/2;
  grid-row: 1/2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.home-service-right .s2a {
  /* border: 1px solid black; */
  grid-column: 2/2;
  grid-row: 2/2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.home-service-right h3 {
  font-size: 2.25rem;
  font-weight: 400;
  margin-bottom: 5px;
}

.home-service-right ol {
  margin-left: 1.25em;
  font-size: 1.25rem;
}

/* service */
@media only screen and (max-width: 1000px) {
  .home-service {
    align-items: center;
  }

  .home-service-inner {
    display: flex;
    flex-direction: column;
    margin-top: 0;                         
    padding: 15px;
    width: 85.5%;
  }

  .home-service-left {
    width: 100%;
  }

  .home-service-left h2 {
    font-size: 1.75rem;
    font-weight: 500;
  }

  .home-service-right {
    width: 100%;
    border-left: 0px;
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
    padding: 2em 0;
    /* margin-left: -15px; */
  }

  .home-service-right .s1 {
    grid-column: 1/2;
    grid-row: 1/2;
    width: 50%;
  }

  .home-service-right .s2 {
    grid-column: 1/2;
    grid-row: 3/4;
    width: 50%;
  }

  .home-service-right .s1a {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .home-service-right .s2a {
    grid-column: 1/2;
    grid-row: 4/5;
  }

  .home-service-right h3 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .home-service ol {
    font-size: 1rem;
  }
  .home-service-right img {
    width: 100%;
    height: 12.5vh;
  }
}

.home-testimoni {
  /* height: 72.5vh; */
  width: 100%;
  display: flex;
  flex-direction: row;
}

.home-testimoni-vid {
  background: #006838;
  padding: 4em 5em;
  width: 100%;
  height: 75vh;
}
.home-testimoni-vid div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.home-testimoni iframe {
  margin: 0 auto;
  border: none;
  position: relative;
  height: 100%;
  width: 80%;
  /* Comment dibawah kalau udah ada testi  */
  /* margin-left: 25%; */
  /* height: 200%; */
}

.home-testimoni-txt {
  background: #248842;
  /* Kalau sudah ada tinggal di un comment */
  display: flex;
  /* display: none; */
  flex-direction: column;
  justify-content: space-between;
  padding: 3em 5em;
  width: 60%;
  height: 75vh;
}

.home-testimoni h3 {
  color: white;
  /* Komen margin-left kalo sudah ada testi  */
  /* margin-left: 47.5%; */
  font-weight: 400;
  font-size: 2rem;
  margin-bottom: 1em;
}

.testimoni-txt-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 7.5%;
}

.home-testimoni-txt h3 {
  /* text-align: center; */
  margin-bottom: 1rem;
} 

.testimoni-txt-inner p {
  width: 100%;
  max-width: 500px;
  /* padding-left: 10%; */
  text-align: center;
  color: white;
  font-size: 1.25rem;
}

.testi-txt-name {
  width: 80%;
  text-align: right;
  color: white;
  padding-bottom: 10%;
}

/* .testimoni-txt-inner button {
  background: none;
  height: 50px;
  width: 20px;
  border: none;
  cursor: pointer;
}

.testimoni-txt-inner button img {
  height: 100%;
  width: 100%;
  transition: 0.05s;
} */

/* .testimoni-txt-inner button img:hover {
  transform: scale(1.15);
} */

/* testimoni */
@media only screen and (max-width: 1400px) {
  .home-testimoni-txt p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1000px) {
  .home-testimoni {
    height: none;
    display: flex;
    flex-direction: column;
  }

  .home-testimoni-vid {
    padding: 1em;
  }

  .home-testimoni-vid h3 {
    /* margin-top: 1em; */
    margin-bottom: 1em;
    margin-top: 0.5em;
    text-align: center;
  }

  .home-testimoni iframe {
    margin-bottom: 2em;
  }

  .home-testimoni-txt {
    width: 100%;
    /* height: 100vh; */
    padding: 2em 5em;
  }

  .home-testimoni-txt h3 {
    text-align: center;
    margin-bottom: 1rem;
  } 

  .testimoni-txt-inner {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .home-testimoni-txt p {
    font-size: 1.25rem;
  }
  .testi-txt-name {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .home-testimoni-txt p {
    font-size: 1rem;
  }
}

.home-footer {
  width: 100%;
  background: white;
}

.home-footer-inner {
  width: 95%;
  padding: 0.1em 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.home-footer-left {
  width: 50%;
}

.home-footer-left img {
  width: 100%;
  max-width: 300px;
  object-fit: cover;
}

.home-footer-right {
  width: 50%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  color: #006838;
}

.home-footer-right .footer-down {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 0.35em;
  margin-top: 0.75em;
}

.home-footer-right .footer-down div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.home-footer-right .footer-down div img {
  width: 20px;
  object-fit: contain;
  margin-right: 0.5em;
}

@media only screen and (max-width: 1000px) {
  .home-footer {
    /* overflow: hidden; */
    /* margin-top: 40%; */
    /* padding-top: 10em; */
    padding-bottom: 10px;
  }
  .home-footer-left {
    width: 50%;
  }
  .home-footer-right .footer-down {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
  }
  .home-footer-right p {
    font-size: 0.75rem;
  }
}
</style>
